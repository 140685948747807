import { render, staticRenderFns } from "./ArticleDetail.vue?vue&type=template&id=738c1c37&scoped=true&"
import script from "./ArticleDetail.vue?vue&type=script&lang=js&"
export * from "./ArticleDetail.vue?vue&type=script&lang=js&"
import style0 from "./ArticleDetail.vue?vue&type=style&index=0&id=738c1c37&prod&lang=scss&scoped=true&"
import style1 from "./ArticleDetail.vue?vue&type=style&index=1&id=738c1c37&prod&scoped=true&lang=sass&"
import style2 from "./ArticleDetail.vue?vue&type=style&index=2&id=738c1c37&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738c1c37",
  null
  
)

export default component.exports